.allPlants-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.allPlants-page .img-cont {
	overflow: hidden;
	height: 200px;
	width: 100%;
	background: #fff;
}
.allPlants-page .img-cont img {
	width: 100%;
	height: 200px;
}

.allPlants-page .p-card .content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 10px;
}
section.allPlants-page .p-card {
	margin-bottom: 1rem;
}
.allPlants-page .p-card:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	padding: 0px;
}

.allPlants-page .p-card .content .title,
.allPlants-page .p-card .content .title2 {
	font-family: "SF UI Display medium";
	font-size: 17px;
	color: #000000b8;
	font-weight: 400;
	margin-bottom: 0;
}

.allPlants-page .filter-side {
	padding: 45px 20px;
	background: #286152;
}

.allPlants-page .filter-side .filter-title {
	font-size: 30px;
	color: #fff;
	font-family: "ZangeziSans07_TRIAL";
}

.allPlants-page .filter-side .title-cont {
	border-bottom: 1px solid #f3f2f2a8;
	margin-bottom: 20px;
}

.allPlants-page .filter-side .maincate-head {
	color: #fff;
	font-size: 18px;
	font-family: "SF UI Display medium";
	margin-top: 20px;
}

.allPlants-page .filter-side .load-more {
	color: #fff;
	font-size: 15px;
	font-family: "SF UI Display medium";
	margin-top: 20px;
	text-decoration: underline;
}

.allPlants-page .filter-side .cate-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ffffff70;
	padding: 10px;
}

.allPlants-page .filter-side .cate-box .title {
	font-size: 14px;
	color: #fff;
	font-family: "SF UI Display medium";
	margin-bottom: 0px;
}

.allPlants-page .filter-side .search {
	background: #286152;
	border: unset;
	border-bottom: 1px solid #fff;
	width: 100%;
	padding: 8px 12px;
	outline: none;
}

.allPlants-page .filter-side .search-bar-cont {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.allPlants-page .filter-side .search-bar-cont svg {
	color: rgba(255, 255, 255, 0.74);
	position: absolute;
	right: 5px;
	font-size: 10px;
	top: 8px;
}

.allPlants-page .filter-side .search-bar-cont input::placeholder {
	color: rgba(255, 255, 255, 0.74) !important;
	font-family: "SF UI Display medium";
}

.allPlants-page .filter-side .price-text {
	font-size: 12px;
	color: #fff;
	font-family: "SF UI Display";
}

.allPlants-page .filter-side .price-filter {
	width: 100%;
	border-radius: 20px;
	padding: 5px 12px;
	border: unset;
}

select.form-control {
	background-image: url(../img/testimonial/Icon\ ionic-ios-arrow-dropdown.png);
}

.HardnessZone select {
	border: none !important;
	outline: none !important;
	background-repeat: no-repeat;
	padding: 0px;
	color: #fff !important;
	background-color: #286152 !important;
	background-position: center right 12px;
	margin-top: 14px;
}

@media (max-width: 1024px) {
	.allPlants-page .p-card .content .title,
	.allPlants-page .p-card .content .title2 {
		font-size: 13px;
	}
}

@media (max-width: 991px) {
	.page-item.active .page-link {
		padding: 12px 20px;
	}
	li.page-item.disabled a.page-link {
		padding: 7px 20px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		border-radius: 0px !important;
		background: #ffffff;
		border: 0px;
		color: #6d6d6d;
		font-size: 25px;
		padding: 8px 16px;
	}
	li.page-item a.page-link {
		padding: 12px 20px;
	}
	ul.pagination li.page-item.disabled a.page-link {
		font-size: 13px;
	}
}

@media (max-width: 820px) {
	.allPlants-page .filter-side {
		padding: 93px 20px;
	}
	.allPlants-page .filter-side .price-text {
		font-size: 20px;
	}
	.allPlants-page .filter-side .filter-title {
		font-size: 30px;
	}
	.allPlants-page .filter-side .load-more {
		font-size: 15px;
	}
	.allPlants-page .p-card .content .title,
	.allPlants-page .p-card .content .title2 {
		font-size: 37px;
	}
	section.allPlants-page .p-card {
		margin-top: 46px;
	}
}

@media (max-width: 768px) {
	.allPlants-page {
		background: none;
	}
}

@media (max-width: 767px) {
	.allPlants-page {
		background: none;
	}
	.allPlants-page .filter-side {
		margin-bottom: 25px;
	}
	.page-item.active .page-link {
		padding: 12px 20px;
	}
	li.page-item.disabled a.page-link {
		padding: 7px 20px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		font-size: 25px;
		padding: 8px 16px;
	}
	li.page-item a.page-link {
		padding: 12px 20px;
	}

	ul.pagination li.page-item.disabled a.page-link {
		font-size: 17px;
	}
}

@media (max-width: 576px) {
	li.page-item a.page-link {
		padding: 12px 9px;
	}
	.page-item.active .page-link {
		padding: 12px 10px;
	}

	ul.pagination li.page-item.disabled a.page-link {
		font-size: 14px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		font-size: 21px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		padding: 8px 4px;
	}
	.page-item {
		padding: 2px;
	}
}

@media (max-width: 414px) {
	.allPlants-page .p-card .content .title,
	.allPlants-page .p-card .content .title2 {
		font-size: 26px;
	}
}

@media (max-width: 390px) {
	.allPlants-page .p-card .content .title,
	.allPlants-page .p-card .content .title2 {
		font-size: 24px;
	}
}

@media (max-width: 375px) {
	.allPlants-page .p-card .content .title,
	.allPlants-page .p-card .content .title2 {
		font-size: 22px;
	}
}
